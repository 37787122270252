.explainer {
    font-size: 24px;
    font-weight: bold;
}

.explainer.span {
    color: #303030;
}

.explainer .ai {
    color: #0d6efd;
}
