.uploadForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.custom-file-upload {
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border-radius: .25rem;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    user-select: none;
    border: 1px solid transparent;
    vertical-align: middle;
}

.custom-file-upload:hover {
    background-color: #0069d9;
    border-color: #0062cc;
}

.custom-file-upload:active {
    background-color: #0062cc;
    border-color: #0056b3;
}

.custom-file-upload > input[type='file'] {
    display: none;
}

.image-preview {
    max-height: 400px;
    max-width: 90%;
    display: block;
    margin: auto; /* this will center the image horizontally */
}

.delete-link-button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    text-decoration: underline;
    padding: 0;
    font-size: inherit;
}
