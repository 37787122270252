body {
  margin: 0;
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px;
  color: #303030;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
    color: #303030;
    font-size: 48px;
}

h2 {
    font-weight: bold;
}

h3 {
    font-size: 18px;
    font-weight: bold;
}

h4 {
    font-size: 16px;
    font-weight: bold;
}