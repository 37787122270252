.App {
    text-align: center;
}

.app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

p {
    line-height: 1.5;
}

br {
    line-height: 3;
}

#preview {
    max-width: 100%;
    max-height: 90vh;
    height: auto !important;
}

.cropper-container {
    max-height: 90vh !important;
    height: auto !important;
    margin-bottom: 50px; /* Add this line */
}

.cropper-crop-box {
    position: relative;
}

#previewContainer {
    margin-bottom: 10px;
}

#submitBtn {
    margin-top: 10px;
}

.spinner-overlay {
    position: fixed; /* Fixed position */
    z-index: 9999; /* Sit on top of everything else */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    display: flex; /* Enable flex */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    text-align: center; /* Center text */
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

.spinner-text {
    margin-top: 20px;
    color: #fff;
    font-size: 20px;
}

label {
    font-size: 12px;
}

#index {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.btn {
    font-size: 18px;
}

