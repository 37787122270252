.footerContainer {
    display: flex;
    align-items: center;
    font-size: 0.5rem;
}

.footerContainer > div {
    display: flex;
}

.footerContainer > div:first-child {
    margin-right: 5px;
}